import * as NS from 'features/Test/namespace'

export const INIT_TEST_DATA: NS.TestData = {
    money: null,
    isFirstAuto: null,
    areasOfUse: [],
    desires: [],
    brends: [],
    carBodyTypes: [],
    climat: null,
    engine: null,
    goals: [],
}